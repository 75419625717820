import * as React from "react";
import { observer } from "mobx-react";
import { EGDSTertiaryButton } from "@egds/react-core/button";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";
import { ToggleContainerProps } from "../typings";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { EGDSHeading } from "@egds/react-core/text";
import { RegionChildrenWithGridContainer } from "src/utils/RegionUtils";

export enum ToggleContainerState {
  ALL_EXPANDED = "expand",
  ALL_COLLAPSED = "collapse",
  NEITHER = "neither",
}

export interface ToggleAllContainerContext {
  collapseAll?: string;
  toggleCollapseAll?: any;
}

const defaultContext = {
  collapseAll: ToggleContainerState.NEITHER,
};
const expandButton = "simpleContainer.expandAllButton";
const collapseButton = "simpleContainer.collapseAllButton";

export const ToggleAllContainerContext = React.createContext<ToggleAllContainerContext>(defaultContext);

export const ToggleAllContainer = observer(
  ({ blossomComponent, templateComponent, flexModuleModelStore }: ToggleContainerProps) => {
    const { formatText } = useLocalization();
    const [collapseAll, setCollapseAll] = React.useState(ToggleContainerState.NEITHER);

    if (!blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { title, innerTitle } = templateComponent.config;

    const toggleCollapseAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { value } = e.currentTarget;

      if (
        value === ToggleContainerState.NEITHER ||
        value === ToggleContainerState.ALL_COLLAPSED ||
        value === ToggleContainerState.ALL_EXPANDED
      ) {
        setCollapseAll(value);
      } else {
        setCollapseAll(ToggleContainerState.NEITHER);
      }
    };

    return (
      <ToggleAllContainerContext.Provider value={{ collapseAll, toggleCollapseAll }}>
        <EGDSSpacing padding={{ inline: "three" }}>
          <div>
            {title && (
              <EGDSHeading tag="h2" size={3}>
                {title}
              </EGDSHeading>
            )}
            {innerTitle && (
              <EGDSHeading tag="h2" size={3}>
                {innerTitle}
              </EGDSHeading>
            )}
            <EGDSSpacing margin={{ block: "three" }}>
              <div data-testid={collapseAll} id="expand-collapse-buttons">
                <EGDSTertiaryButton onClick={toggleCollapseAll} value={ToggleContainerState.ALL_EXPANDED}>
                  {formatText(expandButton)}
                </EGDSTertiaryButton>
                <EGDSTertiaryButton onClick={toggleCollapseAll} value={ToggleContainerState.ALL_COLLAPSED}>
                  {formatText(collapseButton)}
                </EGDSTertiaryButton>
              </div>
            </EGDSSpacing>
          </div>
        </EGDSSpacing>
        <RegionChildrenWithGridContainer templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </ToggleAllContainerContext.Provider>
    );
  }
);

import * as React from "react";
import { observer } from "mobx-react";
import { ToggleContainerProps } from "../typings";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { getExtraClassNameBasedOnParameters } from "components/flexComponents/SimpleContainer/utilityStyles";
import { ToggleAllContainerContext, ToggleContainerState } from "./ToggleAllContainer";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildrenWithGridContainer } from "src/utils/RegionUtils";
import { IntersectionTracker, useClickTracker } from "@shared-ui/clickstream-analytics-context";

export interface ToggleSingleContainerContext {
  modulesHaveBorder?: boolean;
}

const defaultContext = {
  modulesHaveBorder: false,
};

export const ToggleSingleContainerContext = React.createContext<ToggleSingleContainerContext>(defaultContext);

export const ToggleSingleContainer = observer(
  ({ blossomComponent, templateComponent, flexModuleModelStore }: ToggleContainerProps) => {
    /* istanbul ignore next */
    if (!templateComponent || !blossomComponent) {
      return null;
    }

    const {
      metadata: { name: containerName = "" },
    } = templateComponent;
    const {
      rfrrID,
      mobileWidth,
      title,
      innerTitle,
      tabletWidth,
      desktopWidth,
      collapsed,
      hasBorder: modulesHaveBorder,
    } = templateComponent.config;
    const impressionLinkName = rfrrID + " Link";
    const impressionRfrrID = `${rfrrID}.Impression`;

    if (!hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }
    const commonClassNames = [getExtraClassNameBasedOnParameters(templateComponent.config, containerName)]
      .filter((classes) => classes !== "")
      .join(" ");

    const ContainerContent = (containerProps: { includeTitle: boolean; classNames: string }) => (
      <div className={` ${commonClassNames} ${containerProps.classNames} `} role="region">
        {title && (
          <EGDSSpacing>
            <EGDSHeading tag="h2" size={3} className={`${!containerProps.includeTitle && "is-visually-hidden"} `}>
              {title}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        {innerTitle && (
          <EGDSSpacing padding={{ inlinestart: "three" }}>
            <EGDSHeading tag="h3" size={3}>
              {innerTitle}
            </EGDSHeading>
          </EGDSSpacing>
        )}
        <RegionChildrenWithGridContainer templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </div>
    );

    const toggleAllContainerContext = React.useContext(ToggleAllContainerContext);
    const { collapseAll, toggleCollapseAll } = toggleAllContainerContext;
    const [isVisible, setIsVisible] = React.useState(!collapsed);
    const trackProgressiveDisclosure = useClickTracker();
    const toggle = React.useCallback(() => {
      setIsVisible(!isVisible);

      const id = `${rfrrID}.${isVisible ? "closed" : "opened"}`;
      const description = `${rfrrID} ${isVisible ? "collapsed" : "expanded"}`;
      trackProgressiveDisclosure(id, description);
      toggleCollapseAll(ToggleContainerState.NEITHER);
    }, [isVisible]);
    const normalTitle = (
      <EGDSText size={400} weight="bold" theme="default">
        {title}
      </EGDSText>
    );
    const expandoTitle = (
      <EGDSHeading tag="h2" size={4}>
        {title}
      </EGDSHeading>
    );
    const expandoKeyboardPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " ") {
        toggle();
      }
    };
    const toggleSingleContainerView = (
      <Viewport>
        {mobileWidth === "hidden" ? (
          <ViewSmall />
        ) : (
          <ViewSmall>
            <EGDSSpacing padding={{ block: rfrrID ? "two" : "three" }}>
              <div className="SimpleContainer ToggleContainer">
                <EGDSCard border={rfrrID ? true : false}>
                  <div
                    className="CallToAction"
                    tabIndex={0}
                    role="button"
                    aria-expanded={isVisible}
                    onClick={toggle}
                    data-testid="expando-toggle"
                    onKeyDown={expandoKeyboardPress}
                  >
                    <EGDSCardContentSection padded>
                      <EGDSLayoutFlex>
                        <EGDSLayoutFlexItem grow={1}>{rfrrID ? expandoTitle : normalTitle}</EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                          <EGDSCardContentSection padded={false}>
                            <EGDSIcon name={isVisible ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
                          </EGDSCardContentSection>
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSCardContentSection>
                  </div>
                  <EGDSCardContentSection
                    padded={["blockend", "inlinestart", "inlineend"]}
                    className={`${isVisible ? "" : "is-hidden"}`}
                  >
                    <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                      <div>
                        <ContainerContent classNames="SimpleContainer" includeTitle={false} />
                      </div>
                    </EGDSSpacing>
                  </EGDSCardContentSection>
                </EGDSCard>
              </div>
            </EGDSSpacing>
          </ViewSmall>
        )}

        {tabletWidth === "hidden" ? (
          <ViewMedium />
        ) : (
          <ViewMedium>
            <EGDSSpacing padding={{ block: rfrrID ? "two" : "three" }}>
              <div className="SimpleContainer ToggleContainer">
                <EGDSCard border={rfrrID ? true : false}>
                  <div
                    className="CallToAction"
                    tabIndex={0}
                    role="button"
                    aria-expanded={isVisible}
                    onClick={toggle}
                    data-testid="expando-toggle"
                    onKeyDown={expandoKeyboardPress}
                  >
                    <EGDSCardContentSection padded>
                      <EGDSLayoutFlex>
                        <EGDSLayoutFlexItem grow={1}>{rfrrID ? expandoTitle : normalTitle}</EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                          <EGDSCardContentSection padded={false}>
                            <EGDSIcon name={isVisible ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
                          </EGDSCardContentSection>
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSCardContentSection>
                  </div>
                  <EGDSCardContentSection
                    padded={["blockend", "inlinestart", "inlineend"]}
                    className={`${isVisible ? "" : "is-hidden"}`}
                  >
                    <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                      <div>
                        <ContainerContent classNames="SimpleContainer" includeTitle={false} />
                      </div>
                    </EGDSSpacing>
                  </EGDSCardContentSection>
                </EGDSCard>
              </div>
            </EGDSSpacing>
          </ViewMedium>
        )}

        {desktopWidth === "hidden" ? (
          <ViewLarge />
        ) : (
          <ViewLarge>
            <EGDSSpacing padding={{ block: rfrrID ? "two" : "three" }}>
              <div className="SimpleContainer ToggleContainer">
                <EGDSCard border={rfrrID ? true : false}>
                  <div
                    className="CallToAction"
                    tabIndex={0}
                    role="button"
                    aria-expanded={isVisible}
                    onClick={toggle}
                    data-testid="expando-toggle"
                    onKeyDown={expandoKeyboardPress}
                  >
                    <EGDSCardContentSection padded>
                      <EGDSLayoutFlex>
                        <EGDSLayoutFlexItem grow={1}>{rfrrID ? expandoTitle : normalTitle}</EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                          <EGDSCardContentSection padded={false}>
                            <EGDSIcon name={isVisible ? "expand_less" : "expand_more"} size={EGDSIconSize.SMALL} />
                          </EGDSCardContentSection>
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSCardContentSection>
                  </div>
                  <EGDSCardContentSection
                    padded={["blockend", "inlinestart", "inlineend"]}
                    className={`${isVisible ? "" : "is-hidden"}`}
                  >
                    <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                      <div>
                        <ContainerContent classNames="SimpleContainer" includeTitle={false} />
                      </div>
                    </EGDSSpacing>
                  </EGDSCardContentSection>
                </EGDSCard>
              </div>
            </EGDSSpacing>
          </ViewLarge>
        )}
      </Viewport>
    );

    React.useEffect(() => {
      if (collapseAll === ToggleContainerState.ALL_EXPANDED) {
        setIsVisible(true);
      } else if (collapseAll === ToggleContainerState.ALL_COLLAPSED) {
        setIsVisible(false);
      }
    }, [collapseAll]);

    return (
      <ToggleSingleContainerContext.Provider value={{ modulesHaveBorder }}>
        {rfrrID ? (
          <IntersectionTracker linkName={impressionLinkName} referrerId={impressionRfrrID}>
            {toggleSingleContainerView}
          </IntersectionTracker>
        ) : (
          <>{toggleSingleContainerView}</>
        )}
      </ToggleSingleContainerContext.Provider>
    );
  }
);

export default ToggleSingleContainer;
